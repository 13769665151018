import { Analytics } from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';

const analytics = Analytics({
	app: 'grabbarnaflyttweb',
	plugins: [
		googleTagManager({
			containerId: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID
		})
	]
});

export default analytics;
